import { Box } from '@mui/material';
import moment from 'moment/moment';
import { CreateVisitContext } from '../../../../../../../../../../pages/ClientDetails/context';
import AffectedVisitsAccordion from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/ArrivalWindowsAccordion/AffectedVisitsAccordion';
import React, { useCallback, useContext, useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../../../../../../../../../shared/constants';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';
import SelectableTimeSlot from './ArrivalWindowsTableItmes/SelectableTimeSlot';
import { convertDaysToNames } from '../../../../../../constants';

export default function AvailabilityWindowsBody() {
  const {
    availabilityScheduling,
    selectedTimeSlots,
    visitData,
    changeSelectedSlots,
  } = useContext(CreateVisitContext);

  const changeSlots = (newTimeSlot) => {
    if (!availabilityScheduling) {
      return '';
    }
    changeSelectedSlots({ [newTimeSlot.day]: newTimeSlot });
    return '';
  };

  const availabilitiesByTimesOfDay = useMemo(() => {
    const availabilitiesMap = {
      morning: [],
      midday: [],
      afternoon: [],
    };
    if (!availabilityScheduling?.availableArrivalWindows) {
      return availabilitiesMap;
    }
    return availabilityScheduling.timesOfDay.reduce((obj, item) => {
      const availabilitiesList =
        availabilityScheduling?.availableArrivalWindows.filter(
          ({ arrivalStart }) =>
            moment(arrivalStart, H12_TIME_FORMAT).isBetween(
              moment(item.from, H12_TIME_FORMAT),
              moment(item.to, H12_TIME_FORMAT),
              'minutes',
              '[)',
            ),
        );
      return Object.assign(obj, {
        [item.name]: availabilitiesList,
      });
    }, {});
  }, [availabilityScheduling]);

  const checkIsTimeSlotSelected = useCallback(
    ({ day, arrivalEnd, arrivalStart }) => {
      const selectedDay = selectedTimeSlots && selectedTimeSlots[day];
      return selectedDay
        ? selectedDay.arrivalEnd === arrivalEnd &&
            selectedDay.arrivalStart === arrivalStart
        : false;
    },
    [selectedTimeSlots],
  );
  const dayNames = [];
  for (const day in Object.keys(visitData.days)) {
    dayNames.push(convertDaysToNames[Object.keys(visitData.days)[day]]);
  }

  return availabilityScheduling?.timesOfDay.map((timeOfDay) => (
    <AffectedVisitsAccordion
      key={timeOfDay.name}
      title={`${timeOfDay.name}: ${timeOfDay.from} - ${timeOfDay.to}`}
    >
      {availabilitiesByTimesOfDay[timeOfDay.name]?.map(
        ({ arrivalEnd, arrivalStart, weekdays }) => (
          <Box key={`${arrivalEnd}-${arrivalStart}`} sx={{ display: 'flex' }}>
            {[`${arrivalStart} - ${arrivalEnd}`, ...dayNames].map(
              (item, index) =>
                !index ? (
                  <ConstantTableItem
                    key={item}
                    isArrivalWindowItem={!index}
                    name={item}
                  />
                ) : (
                  <SelectableTimeSlot
                    key={item}
                    blocked={!weekdays?.length}
                    fullAvailable={weekdays?.length === dayNames.length}
                    onSelect={() =>
                      changeSlots({
                        day: item,
                        arrivalEnd,
                        arrivalStart,
                      })
                    }
                    selected={checkIsTimeSlotSelected({
                      day: item,
                      arrivalEnd,
                      arrivalStart,
                    })}
                    semiAvailable={weekdays.includes(item)}
                  />
                ),
            )}
          </Box>
        ),
      )}
    </AffectedVisitsAccordion>
  ));
}
