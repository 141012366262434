import { Typography } from '@mui/material';
import React from 'react';
import AvatarGroups from '../../../../components/AvatarGroup';
import BlockedCaregiversSelector from './BlockedCaregiversSelector';
import StatusSelector from './StatusSelector';
import TeamSelector from './TeamSelector';

export const getInfoComponent = ({ type, value, editMode }) => {
  if (type === 'badge') {
    return <StatusSelector />;
  }
  if (type === 'text') {
    return <Typography variant="body1">{value || ''}</Typography>;
  }
  // if (type === 'phone') {
  //   const numericPhoneNumber = value?.replace(/\D/g, '');

  //   const formattedPhoneNumber = numericPhoneNumber
  //     ? `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(
  //         3,
  //         6,
  //       )}-${numericPhoneNumber?.slice(6)}`
  //     : '';
  //   return (
  //     <Typography variant="body1">{formattedPhoneNumber || ''}</Typography>
  //   );
  // }
  if (type === 'team') {
    return editMode ? (
      <TeamSelector />
    ) : (
      <Typography
        variant="body1"
        sx={{ fontStyle: value ? 'normal' : 'italic' }}
      >
        {value || 'No team'}
      </Typography>
    );
  }
  if (type === 'blockedCaregivers') {
    const fullNameArray = Array.isArray(value)
    ? value?.map((CaregiverName) => `${CaregiverName}`)
    : null;
    return editMode ? (
      <BlockedCaregiversSelector />
    ) : (
      <AvatarGroups showTooltip maxLength={5} users={fullNameArray} />
    );
  }
  return false;
};
