import moment from 'moment';
import {
  CreateVisitContext,
  EditCareProgramContext,
} from '../../../../../../../../pages/ClientDetails/context';
import { getCreateVisitConfirmButtonName } from '../../../../../../../../pages/ClientDetails/utils';
import {
  UPDATE_PROGRAM_POPUPS,
  createVisitSteps,
} from '../../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { H12_TIME_FORMAT } from '../../../../../../../../shared/constants';
import CustomDialog from '../../../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../../../components/Dialog/UnsavedChangesContent';
import FullWidthDialog from '../../../../../../../../components/FullWidthDialog';
import {
  checkIsCreateVisitStepConfirmButtonDisabled,
  getNewVisitsList,
} from '../../utils';
import { CreateVisitMultistep } from './CreateVisitMultistep';

export default function CreateCareProgramVisitDialog() {
  const {
    editedVisit,
    effectiveDate,
    closeDialog,
    innerDialogType,
    setEditedVisitData,
    setUpdatedCareProgram,
    showProgramVisitsView,
    updatedCareProgram,
    clientId,
  } = useContext(EditCareProgramContext);

  const [step, setStep] = useState(0);
  const [stepDataLoading, setStepDataLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [availabilityScheduling, setAvailabilityScheduling] = useState(null);
  const [alternativeArrivalWindows, setAlternativeArrivalWindows] =
    useState(null);
  const [availableVisitTasks, setAvailableVisitTasks] = useState([]);
  const [visitData, setVisitData] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(null);

  useEffect(() => {
    if (editedVisit?.days && !editedVisit.arrivalWindowDuration) {
      const { arrivalStart, arrivalEnd } = editedVisit.days[Object.keys(editedVisit.days)[0]];
      const start = moment(arrivalStart, H12_TIME_FORMAT);
      const end = moment(arrivalEnd, H12_TIME_FORMAT);
      editedVisit.arrivalWindowDuration = moment.duration(end.diff(start)).as('minutes').toString();
      editedVisit.effectiveDateStart = moment(editedVisit.effectiveDateStart, H12_TIME_FORMAT)
    }
    setVisitData(editedVisit);
  }, [editedVisit, effectiveDate]);

  const clearCreationData = () => {
    setStep(0);
    setCompletedSteps([]);
    setAvailabilityScheduling(null);
    setSelectedTimeSlots(null);
    setAvailableVisitTasks([]);
    setEditedVisitData();
    setVisitData();
  };
  const goToNextStep = () => {
    if (step === createVisitSteps.length - 1) {
      const updatedVisitsList = getNewVisitsList({
        selectedTimeSlots,
        updatedCareProgram,
        visitData,
        effectiveDate,
        clientId,
      });
      setUpdatedCareProgram(updatedVisitsList);
      clearCreationData();
      showProgramVisitsView();
      return '';
    }
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setStep((prevState) => prevState + 1);
    return '';
  };

  const clearSelectedTimeSlots = useCallback(
    () => setSelectedTimeSlots(null),
    [],
  );

  const closeVisitCreation = () => {
    const {
      arrivalWindowDuration,
      days,
      duration,
      genderPreference,
      skills,
      timesOfDay,
      visitType,
    } = visitData || {};
    const isChangesAdded =
      step > 0 ||
      !!arrivalWindowDuration ||
      !!days.length ||
      !!duration ||
      !!genderPreference ||
      !!skills?.length ||
      !!timesOfDay?.length ||
      !!Object.keys(visitType).length;
    if (isChangesAdded) {
      setOpenConfirmationModal(true);
    } else {
      clearCreationData();
      closeDialog();
    }
  };

  const submitCancelCreation = () => {
    clearCreationData();
    closeDialog();
    setOpenConfirmationModal(false);
  };

  const changeSelectedSlots = useCallback(
    (newSlot) =>
      setSelectedTimeSlots((prevValue) =>
        prevValue ? { ...prevValue, ...newSlot } : newSlot,
      ),
    [],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      alternativeArrivalWindows,
      availabilityScheduling,
      availableVisitTasks,
      changeSelectedSlots,
      clearSelectedTimeSlots,
      completedSteps,
      selectedTimeSlots,
      setAlternativeArrivalWindows,
      setAvailabilityScheduling,
      setAvailableVisitTasks,
      setCompletedSteps,
      setSelectedTimeSlots,
      setStep,
      setStepDataLoading,
      setVisitData,
      step,
      stepDataLoading,
      visitData,
    }),
    [
      alternativeArrivalWindows,
      availabilityScheduling,
      availableVisitTasks,
      changeSelectedSlots,
      clearSelectedTimeSlots,
      completedSteps,
      selectedTimeSlots,
      setAlternativeArrivalWindows,
      setAvailableVisitTasks,
      setStepDataLoading,
      step,
      stepDataLoading,
      visitData,
    ],
  );

  const isStepConfirmationDisabled = useMemo(
    () =>
      !visitData ||
      checkIsCreateVisitStepConfirmButtonDisabled({
        alternativeArrivalWindows,
        selectedTimeSlots,
        step,
        visitData,
      }),
    [alternativeArrivalWindows, selectedTimeSlots, step, visitData],
  );
  return (
    <CreateVisitContext.Provider value={memoizedProviderValue}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenConfirmationModal(false)}
        submitButtonName="confirm"
        submitCallback={submitCancelCreation}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <UnsavedChangesContent />
      </CustomDialog>
      <FullWidthDialog
        backButtonCallback={closeVisitCreation}
        cancelButtonName="cancel"
        cancelCallback={closeVisitCreation}
        disabledSubmit={isStepConfirmationDisabled}
        scrollable={step !== 1}
        submitButtonName={getCreateVisitConfirmButtonName(step)}
        submitCallback={goToNextStep}
        title={
          editedVisit?.visitId
            ? 'Edit a Care Program Visit'
            : 'Add a Care Program Visit'
        }
        openDialog={innerDialogType === UPDATE_PROGRAM_POPUPS.addNewVisit}
      >
        <CreateVisitMultistep />
      </FullWidthDialog>
    </CreateVisitContext.Provider>
  );
}
