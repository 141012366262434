import { Box, Typography } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import { checkIsDayDisabled } from '../../shared/utils/common';
import {
  customItem,
  disabledItem,
  requiredAsterisk,
  titleStyles,
} from './style';

export default function HorizontalItemsSelector({
  disabled = false,
  disabledDays = {},
  extendingProperties = {},
  gap = '',
  handleChange = () => {},
  isRequired = false,
  options = [],
  selectedDays = [],
  title = '',
}) {
  const checkIsDisabled = (name) =>
    disabled || checkIsDayDisabled(name, disabledDays);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Typography
        variant="body1"
        sx={{
          ...titleStyles,
          ...(isRequired ? requiredAsterisk : {}),
        }}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: gap || '4px' }}>
        {options?.map(({ name, value }, index) => (
          <Box
            className={selectedDays[index] ? 'selected' : ''}
            component="button"
            disabled={checkIsDisabled(name)}
            onClick={handleChange}
            key={index}
            name={name}
            sx={{
              ...customItem,
              ...(checkIsDisabled(name) ? disabledItem : {}),
              ...extendingProperties,
            }}
          >
            {value}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

HorizontalItemsSelector.propTypes = {
  disabled: bool,
  disabledDays: instanceOf(Object),
  extendingProperties: instanceOf(Object),
  gap: string,
  handleChange: func,
  isRequired: bool,
  options: instanceOf(Array),
  selectedDays: instanceOf(Object),
  title: string,
};
