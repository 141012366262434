import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetTeamsHistoryQuery } from '../../../../../../api/Administration/api';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import {
  HISTORY_UPDATE_FORMAT,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '../../../../../../shared/constants';
import { getNoData } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomTable from '../../../../../../components/Table';
import { teamsHistoryColumns } from './constants';

export default function TeamsHistory() {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);
  const {
    data,
    isLoading,
    error: getTeamsHistoryError,
  } = useGetTeamsHistoryQuery();
  useEffect(() => {
    if (getTeamsHistoryError) {
      setAlert({
        errorData: getTeamsHistoryError,
        type: 'error',
      });
    }
  }, [getTeamsHistoryError, setAlert]);

  const updatedEvents = data
  ?.map((event, index) => {
    const changes = event?.changes ? JSON.parse(event.changes) : null;
    if (!changes) return null;

    // Collect all changes in an array
    const finalChanges = changes.flatMap((item) => {
      // Default structure for order and name
      if (item.propertyName === 'order') {
        return [{ 
          ...item,
        }];
      } else if (item.propertyName === 'name') {
        return [{ 
          ...item,
        }];
      } else if (item.propertyName === 'color') {
        return [
          {
            type: item.type,
            operation: item.operation,
            propertyName: 'Color',
            from: item.from.color,
            to: item.to.color,
          },
          {
            type: item.type,
            operation: item.operation,
            propertyName: 'Font Color',
            from: item.from.fontColor,
            to: item.to.fontColor,
          }
        ];
      } else {
        return [{ 
          ...item,
        }];
      }
    });

    return {
      ...event,
      changes: finalChanges,
      dateCreated: moment(event.dateCreated).format(HISTORY_UPDATE_FORMAT),
      id: index + 1,
    };
  })
  .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateCreated);
    const dateB = new Date(b.dateCreated);
    return dateB - dateA;
  });
  return (
    <Box sx={{ mt: '30px' }}>
      <CustomTable
        apiRef={apiRef}
        columns={teamsHistoryColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        noRowsOverlay={() => getNoData({ title: 'No Teams History yet' })}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}
