import { Box, Chip, Typography } from '@mui/material';
import LunchBreakIcon from '../../img/icons/lunch-icon.svg';
import moment from 'moment';
import { instanceOf, string } from 'prop-types';
import React, { useState } from 'react';
import { eventsByCalendarTypes } from '../../shared/constants';
import { checkIfShowArrivalWindow } from '../../shared/utils/common';
import {
  CaregiverCalendarEventArrivalTime,
  CaregiverCalendarEventMainInfoCard,
} from './components/CaregiverCalendarEventsComponents';
import {
  SchedulingEventArrivalTime,
  SchedulingEventMainInfoCard,
} from './components/SchedulingEventsComponents';
import TravelTime from './components/TravelTime';
import WithoutArrivalTime from './components/WithoutArrivalTime';
import { calendarCellHeightMultiplier } from './constant';
import {
  arrivalWindowWrapper,
  chipEndStyle,
  chipStartStyle,
  durationLabelStyles,
  durationStyle,
  lunchBrakeTitleStyles,
  lunchBrakeWrapper,
} from './styles';

export default function SchedulingEventComponent({
  event = {},
  eventType = '',
}) {
  const arrivalStart = moment(event.arrivalStart);
  const arrivalEnd = moment(event.arrivalEnd);
  const checkIfShowArrival = checkIfShowArrivalWindow(event?.status);
  const [hovered, setHovered] = useState(false);
  const arrivalWindowHeight =
    moment(arrivalEnd).diff(arrivalStart, 'minutes') *
    calendarCellHeightMultiplier;
  const arrivalWindowStartPosition =
    moment(event.start).diff(arrivalStart, 'minutes') *
    calendarCellHeightMultiplier;

  const arrivalEndPosition =
    moment(arrivalEnd).diff(event.start, 'minutes') *
      calendarCellHeightMultiplier -
    10;
  const travelTime = event?.isShadowVisit
    ? Number(event?.shadowTravelTime)
    : Number(event?.travelTime);
  return (
    <Box sx={{ height: '100%' }}>
      {event.title !== 'arrival' && event.title !== 'Lunch break' ? (
        <Box
          sx={{ height: '100%' }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {event?.travelTime > 0 && moment(event?.end).isAfter(moment()) && (
            <TravelTime travelTime={travelTime} />
          )}
          {eventType === eventsByCalendarTypes.scheduling && (
            <SchedulingEventMainInfoCard
              arrivalEnd={arrivalEnd}
              arrivalStart={arrivalStart}
              checkIfShowArrival={checkIfShowArrival}
              event={event}
              hovered={hovered}
            />
          )}
          {eventType === eventsByCalendarTypes.caregiverCalendar && (
            <CaregiverCalendarEventMainInfoCard
              arrivalEnd={arrivalEnd}
              arrivalStart={arrivalStart}
              checkIfShowArrival={checkIfShowArrival}
              event={event}
              hovered={hovered}
            />
          )}
          {hovered && (
            <Box>
              <Chip
                label={moment(event.start).format('h:mm A')}
                sx={chipStartStyle}
              />
              <Chip
                label={moment(event.end).format('h:mm A')}
                sx={chipEndStyle}
              />
              <Box sx={durationStyle}>
                <Typography sx={durationLabelStyles}>
                  Duration {event.duration} min
                </Typography>
              </Box>
              {eventType === eventsByCalendarTypes.scheduling &&
                !!event.arrivalTimeDuration &&
                checkIfShowArrival && (
                  <SchedulingEventArrivalTime
                    arrivalEnd={arrivalEnd}
                    arrivalStart={arrivalStart}
                    arrivalEndPosition={arrivalEndPosition}
                    arrivalWindowHeight={arrivalWindowHeight}
                    arrivalWindowStartPosition={arrivalWindowStartPosition}
                    event={event}
                  />
                )}
              {eventType === eventsByCalendarTypes.caregiverCalendar &&
                !!event.arrivalTimeDuration &&
                checkIfShowArrival && (
                  <CaregiverCalendarEventArrivalTime
                    arrivalEnd={arrivalEnd}
                    arrivalStart={arrivalStart}
                    arrivalEndPosition={arrivalEndPosition}
                    arrivalWindowHeight={arrivalWindowHeight}
                    arrivalWindowStartPosition={arrivalWindowStartPosition}
                    event={event}
                  />
                )}
              {event.withArrival === 'withoutArrival' && (
                <WithoutArrivalTime
                  arrivalStart={arrivalStart}
                  arrivalWindowStartPosition={arrivalWindowStartPosition}
                  event={event}
                />
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: '100%' }}>
          {event.title === 'arrival' ? (
            <Box sx={arrivalWindowWrapper}>Arrival Time Window</Box>
          ) : (
            <Box sx={lunchBrakeWrapper}>
              <img src={LunchBreakIcon} alt="lunchbreak" />
              <Typography sx={lunchBrakeTitleStyles}>
                {event.duration} min
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

SchedulingEventComponent.propTypes = {
  event: instanceOf(Object),
  eventType: string,
};
