import { Box, Button, Divider, Typography } from '@mui/material';
import { useGetUserProfileDataQuery } from '../../../api/UserData/api';
import leftArrow from '../../../img/icons/iconButtons/leftArrow.svg';
import rightArrow from '../../../img/icons/iconButtons/rightArrow.svg';
import optimizeIcon from '../../../img/icons/optimize_scheduling_icon.svg';
import HolidayIcon from '../../../img/icons/scheduling-holiday-icon.svg';
import moment from 'moment';
import { truncateName } from '../../../pages/Scheduling/constants';
import { holidayDateWrapper } from '../../../pages/Scheduling/styles';
import { bool, func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-big-calendar';
import { COLORS } from '../../../shared/styles/Theme';
import AlertContext from '../../../components/Alert';
import CustomTooltip from '../../../components/CustomTooltip';
import PickerWithButtonField from './PickerWithButtonField';
import {
  customNavigationWrapper,
  optimizeButtonStyles,
  optimizeSchedulingStyles,
} from './styles';
import styles from './styles.module.css';

export default function CustomNavigation({
  date = {},
  isDateHoliday = {},
  isEdit = false,
  isTable = false,
  onNavigate = () => {},
  reloadSchedulingForDate = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { data: userProfile, error: profileError } =
    useGetUserProfileDataQuery();

  const [currentDate, setCurrentDate] = useState(moment(date));

  useEffect(() => {
    if (profileError) {
      setAlert({
        errorData: profileError,
        type: 'error',
      });
    }
  }, [profileError, setAlert]);
  const handlePrev = () => {
    const prevDate = moment(currentDate).subtract(1, 'day');
    setCurrentDate(prevDate);
    if (isTable) {
      onNavigate(prevDate.toDate());
    } else {
      onNavigate(Navigate.DATE, prevDate.toDate());
    }
  };

  const handleNext = () => {
    const nextDate = moment(currentDate).add(1, 'day');
    setCurrentDate(nextDate);
    if (isTable) {
      onNavigate(nextDate.toDate());
    } else {
      onNavigate(Navigate.DATE, nextDate.toDate());
    }
  };
  const navigateToCurrentDate = () => {
    if (isTable) {
      onNavigate(moment().toDate());
    } else {
      onNavigate(Navigate.DATE, moment().toDate());
    }
    setCurrentDate(moment());
  };
  const maxAvailableDate = moment().startOf('week').add(13, 'days');

  const onDateChange = (newDate) => {
    setCurrentDate(newDate);
    onNavigate(Navigate.DATE, newDate);
    if (isTable) {
      onNavigate(newDate.toDate());
    } else {
      onNavigate(Navigate.DATE, newDate.toDate());
    }
  };

  const isReloadSchedulingForDateAvailable =
    !isTable && 
    (userProfile && userProfile.roles.includes('ADMIN')) &&
    moment(date).isAfter(moment(), 'days');
  const isTodayButtonAvailable = !moment(date).isSame(moment(), 'day');
  return (
    <Box
      sx={{
        ...customNavigationWrapper,
        ml: isTable ? '10px' : '24px',
        mb: isTable ? 0 : '30px',
      }}
      className={`${styles.toolbar} dndToolbar`}
    >
      <Box className={styles.buttonGroup}>
        <button
          className={styles.calendarButtons}
          type="button"
          onClick={handlePrev}
        >
          <img src={leftArrow} alt="" />
        </button>
        <button
          disabled={currentDate.isSame(maxAvailableDate, 'day')}
          style={{
            cursor: currentDate.isSame(maxAvailableDate, 'day')
              ? 'not-allowed'
              : 'pointer',
          }}
          className={styles.calendarButtons}
          type="button"
          onClick={handleNext}
        >
          <img src={rightArrow} alt="" />
        </button>
      </Box>
      <Box sx={{ mr: '16px' }}>
        <PickerWithButtonField
          isDateHoliday={isDateHoliday}
          label={currentDate.format('MMMM DD, YYYY')}
          onDateChange={onDateChange}
          setValue={setCurrentDate}
          value={currentDate}
        />
        {isDateHoliday.id && (
          <Box sx={holidayDateWrapper}>
            <img src={HolidayIcon} alt="" />
            <Typography variant="h6" sx={{ ml: '6px' }}>
              {truncateName(isDateHoliday?.name, 20, 10)}
            </Typography>
          </Box>
        )}
      </Box>
      {!isEdit && (
        <Box sx={optimizeSchedulingStyles}>
          {isTodayButtonAvailable && (
            <Button
              color="secondary"
              disableElevation
              onClick={navigateToCurrentDate}
              sx={optimizeButtonStyles}
              variant="outlined"
            >
              Today
            </Button>
          )}
          {isReloadSchedulingForDateAvailable && isTodayButtonAvailable && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: COLORS.blue[20] }}
            />
          )}
          {isReloadSchedulingForDateAvailable && (
            <CustomTooltip title="By hitting it you will relaunch the scheduling engine. It will reoptimize all the visits initially scheduled for this day.">
              <Button
                color="secondary"
                disableElevation
                onClick={reloadSchedulingForDate}
                startIcon={<img src={optimizeIcon} alt="optimize" />}
                sx={optimizeButtonStyles}
                variant="outlined"
              >
                Optimize scheduling
              </Button>
            </CustomTooltip>
          )}
        </Box>
      )}
    </Box>
  );
}

CustomNavigation.propTypes = {
  date: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  isEdit: bool,
  isTable: bool,
  onNavigate: func,
  reloadSchedulingForDate: func,
};
