import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';

const getHistoryList = (arr) => {
  const objectDescription = JSON?.parse(arr?.objectDescription);
  const propertyName = (name) => {
    if (name === "name") {
      const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
      return `Team ${capitalized} `;
    }
  };
  return (
    <ul className={styles.bulletList}>
      {arr?.changes?.map((item, index) => {
        const key = `${item.operation}-${item.propertyName}-${item.id}${index}`;
        if (item.operation === 'update') {
          return (
            <li
              key={key}
              className={
                item.to === ''
                  ? `${styles.bulletListItem} ${styles.removedType}`
                  : styles.bulletListItem
              }
            >
              <span className={styles.boldWeight}>
                {(item.propertyName === 'order' ||
                  item.propertyName === 'color' || item.propertyName != "name" ) &&
                  objectDescription?.name}
              </span>
              {propertyName(item.propertyName)}
              {item.to !== '' ? (
                <span>
                  is
                  <span className={styles.boldWeight}>{item.visitType}</span>
                  changed from
                  <span className={styles.oldTime}>{item.from}</span> to
                  <span className={styles.newTime}> {item.to}</span>
                </span>
              ) : (
                <span> was removed</span>
              )}
            </li>
          );
        }
        if (item.operation === 'remove') {
          return (
            <li
              key={key}
              className={`${styles.bulletListItem} ${styles.removedType}`}
            >
              Team Removed:
              <span className={styles.boldWeight}>{item.nameFrom}</span>(
              {item.from})
            </li>
          );
        }
        if (item.operation === 'insert') {
          return (
            <li
              key={key}
              className={`${styles.bulletListItem} ${styles.addedType}`}
            >
              Team Added:
              <span className={styles.boldWeight}>{item.nameTo}</span>({item.to}
              )
            </li>
          );
        }
        return false;
      })}
    </ul>
  );
};
export default function TeamsHistoryBullet({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

TeamsHistoryBullet.propTypes = {
  changes: instanceOf(Object),
};
