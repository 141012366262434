import moment from 'moment';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../../../shared/constants';
import { convertDaysToNames } from '../../../../../../constants';

export const getSummaryData = (data) => {
  const {
    careProgramVisits,
    effectiveDate,
    selectedTimeSlots,
    temporaryVisitData,
    userData,
    visitData,
  } = data;
  const arrivalWindows = [];
  for (let day in visitData.days) {
    const dayName = convertDaysToNames[day]
  }
  // const arrivalWindows = visitData?.days?.map((day) => {
  //   if (Object.keys(visitData?.days).find((dayData) => convertDaysToNames[dayData] === day)) {
  //     return {
  //       ...(selectedTimeSlots && selectedTimeSlots[day]
  //         ? selectedTimeSlots[day]
  //         : { day }),
  //       initialDayData: Object.keys(visitData?.days).find((dayData) => convertDaysToNames[dayData] === day),
  //     };
  //   }
  //   if (selectedTimeSlots && selectedTimeSlots[day])
  //     return selectedTimeSlots[day];
  //   return { day };
  // });


  return {
    client: `${userData?.firstName} ${userData?.lastName}`,
    address:`${userData?.street} ${userData?.city}, ${userData?.state}, ${userData?.zipCode}, ${userData?.country}`,
    visitType: visitData?.visitTypeDetails.name,
    duration: visitData?.duration,
    recurrence: visitData?.recurrence,
    effectiveDate: moment(effectiveDate, ISO_DATE_ONLY_FORMAT).format(
      SHORT_DATE_FORMAT,
    ),
    arrivalWindows,
  };
};
