import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const ADMINISTRATION_API_KEY = 'administrationApi';
const administrationApi = createApi({
  reducerPath: ADMINISTRATION_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    // Territory
    getTerritory: builder.query({
      query: () => ({
        url: 'crud/teams-territories/',
        method: 'GET',
        params: {
        index: "type-index",
        pk: "type",
        pkValue: "territory",
        sk: "active",
        skValue: "True",
        filters: [],
        },
      }),
      transformResponse: (response) =>
        response.sort((a, b) => a.name.localeCompare(b.name)),
      providesTags: () => [
        { type: 'territories', id: 'ALL' },
        { type: 'teams', id: 'ALL' },
      ],
    }),
    updateTerritoryById: builder.mutation({
      query: (payload) => ({
        url: `crud/teams-territories/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'territories', id: 'ALL' }],
    }),
    createTerritoryId: builder.mutation({
      query: (payload) => ({
        url: `crud/teams-territories/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'territories', id: 'ALL' }],
    }),
    deleteTerritoryId: builder.mutation({
      query: (id) => ({
        url: `crud/teams-territories/`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'territories', id: 'ALL' }],
    }),
    getTerritoryHistory: builder.query({
      query: () => ({
        url: 'history/teams-territories/',
        method: 'GET',
        params: {
          index: "type-index",
          pk: "type",
          pkValue: "territory",
          },
      }),
      providesTags: () => [{ type: 'territories', id: 'ALL' }],
    }),
    // holidays
    getHolidays: builder.query({
      query: () => ({
        url: 'crud/holidays',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    createHoliday: builder.mutation({
      query: (payload) => ({
        url: 'holidays',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    deleteEventsForHoliday: builder.mutation({
      query: (payload) => ({
        url: 'holidays/delete-events',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    deleteEventsForExistingHoliday: builder.mutation({
      query: ({ payload, id }) => ({
        url: `holidays/${id}/delete-events`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    updateHoliday: builder.mutation({
      query: ({ payload, id }) => ({
        url: `holidays/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    deleteHolidays: builder.mutation({
      query: ({ payload, holidayId }) => ({
        url: `holidays/${holidayId}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    getHolidaysHistory: builder.query({
      query: () => ({
        url: 'holidays/history',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'holidays', id: 'ALL' }],
    }),
    // caregiver Order
    getCaregiversOrder: builder.query({
      query: () => ({
        url: '/caregiver/order',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'caregiversOrder', id: 'ALL' }],
    }),
    updateCaregiversOrder: builder.mutation({
      query: (payload) => ({
        url: '/caregiver/order',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'caregiversOrder', id: 'ALL' }],
    }),
    // Teams
    getTeams: builder.query({
      query: () => ({
        url: 'crud/teams-territories/',
        method: 'GET',
        params: {
          index: "type-index",
          pk: "type",
          pkValue: "team",
          sk: "active",
          skValue: "True",
          filters: []
        }
      }),
      transformResponse: (response) =>
        response.sort((a, b) => a.name.localeCompare(b.name)),
      providesTags: () => [
        { type: 'teams', id: 'ALL' },
        { type: 'territories', id: 'ALL' },
        { type: 'deleteTeams', id: 'ALL' },
      ],
    }),
    updateTeamsById: builder.mutation({
      query: (payload) => ({
        url: `crud/teams-territories/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'teams', id: 'ALL' }],
    }),
    createTeamById: builder.mutation({
      query: (payload) => ({
        url: `crud/teams-territories/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'teams', id: 'ALL' }],
    }),
    deleteTeamById: builder.mutation({
      query: (id) => ({
        url: `crud/teams-territories/`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'deleteTeams', id: 'ALL' }],
    }),
    getTeamsHistory: builder.query({
      query: () => ({
        url: 'history/teams-territories/',
        method: 'GET',
        params: {
          index: "type-index",
          pk: "type",
          pkValue: "team"
        }
      }),
      providesTags: () => [
        { type: 'teams', id: 'ALL' },
        { type: 'deleteTeams', id: 'ALL' },
      ],
    }),
    // Users

    getUsers: builder.query({
      query: () => ({
        url: 'users',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'users', id: 'ALL' }],
    }),
    updateUserById: builder.mutation({
      query: ({ payload, externalId }) => ({
        url: `users/${externalId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'users', id: 'ALL' }],
    }),
    createUserById: builder.mutation({
      query: (payload) => ({
        url: `users`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'users', id: 'ALL' }],
    }),
    deleteUserById: builder.mutation({
      query: (externalId) => ({
        url: `users/${externalId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'users', id: 'ALL' }],
    }),
    getUsersHistory: builder.query({
      query: () => ({
        url: 'users/history',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'users', id: 'ALL' }],
    }),

    // Scheduling Engine
    getWeekendsSchedulingDays: builder.query({
      query: () => ({
        url: 'weekends-scheduling/schema',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'weekendsScheduling', id: 'ALL' }],
    }),
    updateWeekendsSchedulingDays: builder.mutation({
      query: ({ payload }) => ({
        url: 'weekends-scheduling/schema',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'weekendsScheduling', id: 'ALL' }],
    }),
    getWeekendsSchedulingDaysHistory: builder.query({
      query: () => ({
        url: 'weekends-scheduling/schema/history',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'weekendsScheduling', id: 'ALL' }],
    }),
  }),
});
export const {
  // territories
  useGetTerritoryQuery,
  useUpdateTerritoryByIdMutation,
  useDeleteTerritoryIdMutation,
  useCreateTerritoryIdMutation,
  useGetTerritoryHistoryQuery,

  // holidays
  useGetHolidaysQuery,
  useCreateHolidayMutation,
  useUpdateHolidayMutation,
  useDeleteHolidaysMutation,
  useGetHolidaysHistoryQuery,
  useDeleteEventsForHolidayMutation,
  useDeleteEventsForExistingHolidayMutation,
  // caregiver Order
  useGetCaregiversOrderQuery,
  useUpdateCaregiversOrderMutation,

  // Teams
  useGetTeamsQuery,
  useUpdateTeamsByIdMutation,
  useDeleteTeamByIdMutation,
  useCreateTeamByIdMutation,
  useGetTeamsHistoryQuery,
  // users
  useGetUsersQuery,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useCreateUserByIdMutation,
  useGetUsersHistoryQuery,
  // scheduling Engine
  useGetWeekendsSchedulingDaysQuery,
  useGetWeekendsSchedulingDaysHistoryQuery,
  useUpdateWeekendsSchedulingDaysMutation,
} = administrationApi;

export default administrationApi;
