import { Box, Typography } from '@mui/material';
import { bool, instanceOf, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { customLabelStyles, customSelectedValueStyles } from './style';

export default function SelectRenderValue({
  options = [],
  isMultiselect = false,
  placeHolder = '',
  selectedValue = '' || {} || 0,
}) {
  const getValue = () =>
    isMultiselect
      ? selectedValue
          ?.map((selectedItem) => options.find((item) => item.id === selectedItem)?.name)
          ?.join(', ')
      : options.find((item) => item.id === selectedValue)?.name;

  
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        height: '100%',
        pb: '3px',
      }}
    >
      <Box
        className={`animated-placeholder ${
          selectedValue ? 'has-selected-items' : ''
        }`}
        sx={{ ...customLabelStyles }}
      >
        {placeHolder}
      </Box>
      <Box
        className="animated-chosen-item"
        sx={{ ...customSelectedValueStyles }}
      >
        <Typography noWrap>{getValue()}</Typography>
      </Box>
    </Box>
  );
}

SelectRenderValue.propTypes = {
  options: instanceOf(Array),
  isMultiselect: bool,
  placeHolder: string,
  selectedValue: oneOfType([
    instanceOf(Array),
    string,
    instanceOf(Object),
    number,
  ]),
};
