import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { CAREGIVER_LOCAL_STORAGE_KEYS } from '../pages/Caregivers/constants';
import { CLIENT_LOCAL_STORAGE_KEYS } from '../pages/Clients/constants';
import { LOCAL_STORAGE_KEYS } from '../pages/Scheduling/constants';
import { pcaInstance } from '../config';

const getAccountInfo = () => {
  const accounts = pcaInstance.getAllAccounts();
  return accounts[0]; // Is this the correct way ? .
};

export const refreshAccessToken = async () => {
  const account = getAccountInfo();
  const silentRequest = {
    account,
    scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
  };

  try {
    const token = await pcaInstance.acquireTokenSilent(silentRequest);
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return pcaInstance
        .acquireTokenPopup(silentRequest)
        .then((resp) => resp.accessToken)
        .catch(() => {
          localStorage.removeItem('accessToken');
          window.location.replace(process.env.REACT_APP_BASE_URL);
        });
    }
    localStorage.removeItem('accessToken');
    await pcaInstance.logoutRedirect();
    localStorage.removeItem(LOCAL_STORAGE_KEYS.currentDate);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.teamsFilter);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.temporaryScrollPosition);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.scrollPosition);
    localStorage.removeItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverStatus);
    localStorage.removeItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverTeamsFilter);
    localStorage.removeItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverSearch);
    localStorage.removeItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPhoneNumber);
    localStorage.removeItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPageSize);
    localStorage.removeItem(CLIENT_LOCAL_STORAGE_KEYS.clientStatus);
    localStorage.removeItem(CLIENT_LOCAL_STORAGE_KEYS.clientTeamsFilter);
    localStorage.removeItem(CLIENT_LOCAL_STORAGE_KEYS.clientSearch);
    localStorage.removeItem(CLIENT_LOCAL_STORAGE_KEYS.clientPhoneNumber);
    localStorage.removeItem(CLIENT_LOCAL_STORAGE_KEYS.clientPageSize);

  }
  return true;
};

export const baseQueryDBMD = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_NEW_API}api`,
  prepareHeaders: async (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('authorization', `${token}`);
    }
    return headers;
  },
});
