import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { visitTypeIcons } from '../../../../../../../../../../shared/constants';
import NumericInput from '../../../../../../../../../../components/NumericInput';
import CustomSelect from '../../../../../../../../../../components/Select';
import { selectVisitTypeStyles } from '../styles';

export default function VisitTypeBlock({
  changeDuration = () => {},
  changeVisitType = () => {},
  filtersData = {},
  visitData = {},
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CustomSelect
        addStyles={selectVisitTypeStyles}
        endIcon={
          <img
            style={{ marginRight: '24px' }}
            src={visitTypeIcons[visitData?.visitTypeDetails?.name?.toLowerCase()]}
            alt={visitData?.visitTypeDetails?.name}
          />
        }
        icons={visitTypeIcons}
        id="visitType"
        isLoading={false}
        isRequired
        options={filtersData?.visitTypes}
        placeHolder="Visit type"
        selectedValue={visitData?.visitTypeDetails}
        setValue={changeVisitType}
        withIcon
      />
      <NumericInput
        changeValue={changeDuration}
        disabled={visitData?.visitType?.name !== 'Custom visit'}
        isRequired
        label="Duration"
        oneTimeVisit
        step={5}
        value={visitData?.duration || ''}
      />
    </Box>
  );
}

VisitTypeBlock.propTypes = {
  changeDuration: func,
  changeVisitType: func,
  filtersData: instanceOf(Object),
  visitData: instanceOf(Object),
};
