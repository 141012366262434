import { useGetCaregiversListQuery } from '../../../../../api/Caregivers/api';
import {
  useApplyCallOutMutation,
  useCancelCallOutMutation,
  useCheckCallOutPossibilityMutation,
} from '../../../../../api/Scheduler/api';
import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '../../../../../pages/Scheduling/constants';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  DATE_ONLY_STANDARD_FORMAT,
  FULL_DATE_H24_STANDARD_FORMAT,
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
} from '../../../../../shared/constants';
import { convertDateToUTC } from '../../../../../shared/utils/common';
import AlertContext from '../../../../../components/Alert';
import CustomDialog from '../../../../../components/Dialog';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import { ScheduleCaregiverManagementContext } from '../../../context';
import { callOutSteps } from '../constants';
import { checkRequiredFieldsFilled } from '../utils';
import CheckPossibilityView from './components/CheckPossibilityView';
import SettingsView from './components/SettingsView';
import { filterCaregiversByTeams } from './constants';

export default function CreateCallOut() {
  const { setAlert } = useContext(AlertContext);
  const [fieldsError, setFieldsError] = useState(null);

  const {
    addingCallOutStep,
    callOutData,
    closeCallOutCreation,
    setCallOutData,
    showPossibilityView,
    chosenCaregiver,
    caregiversList
  } = useContext(ScheduleCaregiverManagementContext);



  const [
    checkCallOutPossibility,
    {
      data: possibilityData,
      isLoading: isUpdating,
      error: checkCallOutPossibilityError,
    },
  ] = useCheckCallOutPossibilityMutation();
  const [applyCallOut, { error: applyCallOutError }] =
    useApplyCallOutMutation();
  const [cancelCallOut, { error: cancelCallOutError }] =
    useCancelCallOutMutation();
  useEffect(() => {
    if (
      checkCallOutPossibilityError ||
      applyCallOutError ||
      cancelCallOutError
    ) {
      const errorData =
        checkCallOutPossibilityError ||
        applyCallOutError ||
        cancelCallOutError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    applyCallOutError,
    cancelCallOutError,
    checkCallOutPossibilityError,
    setAlert,
  ]);

  const isDataChanged = useMemo(
    () => Object.values(callOutData).some((value) => !!value),
    [callOutData],
  );

  const isRequiredFieldsFilled = useMemo(
    () => checkRequiredFieldsFilled(callOutData),
    [callOutData],
  );

  const closeDialog = useCallback(() => {
    closeCallOutCreation();
    setFieldsError(null);
  }, [closeCallOutCreation]);

  const cancelCalloutCreation = () => {
    if (possibilityData?.requestId) {
      cancelCallOut({
        requestId: possibilityData?.requestId,
      });
    }
    closeCallOutCreation();
    closeDialog();
  };

  const confirmCallout = () => {
    applyCallOut({
      requestId: possibilityData?.requestId,
    });
    closeDialog();
  };

  const getFormattedDate = ({ date, time }) =>
    date &&
    time &&
    `${date.format(DATE_ONLY_STANDARD_FORMAT)} ${time?.format(
      H24_TIME_FORMAT,
    )}`;
  const optimizeScheduling = () => {
    const requestStartDate = getFormattedDate({
      date: moment(callOutData?.callOutStartDate, DATE_ONLY_STANDARD_FORMAT),
      time: moment(callOutData?.callOutStartTime, H12_TIME_FORMAT),
    });
    const requestEndDate = getFormattedDate({
      date: moment(callOutData?.callOutEndDate, DATE_ONLY_STANDARD_FORMAT),
      time: moment(callOutData?.callOutEndTime, H12_TIME_FORMAT),
    });
    const data = {
      id: null,
      requestType: 'CALLOUT',
      requestStart: convertDateToUTC({
        date: requestStartDate,
        dateFormat: FULL_DATE_H24_STANDARD_FORMAT,
        dateTimeZone: CALIFORNIA_TIME_ZONE,
      }),
      requestEnd: convertDateToUTC({
        date: requestEndDate,
        dateFormat: FULL_DATE_H24_STANDARD_FORMAT,
        dateTimeZone: CALIFORNIA_TIME_ZONE,
      }),
      caregiver: {
        id: callOutData.caregiver.id,
        firstName: callOutData.caregiver.firstName,
        lastName: callOutData.caregiver.lastName,
      },
      details: callOutData.details,
    };

    checkCallOutPossibility({
      caregiverId: callOutData.caregiver.id,
      payload: data,
    });
    showPossibilityView();
  };

  const activeCaregivers = useMemo(
    () =>
      caregiversList?.filter(
        ({ primaryTeams, secondaryTeams, status }) =>
          status?.toLowerCase() === 'active' &&
          (primaryTeams.length || secondaryTeams?.length),
      ),
    [caregiversList],
  );
  const chosenFilterTeams = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.teamsFilter),
  );
  const filteredCaregivers = useMemo(
    () => filterCaregiversByTeams(activeCaregivers, chosenFilterTeams),
    [activeCaregivers, chosenFilterTeams],
  );
  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        disabledSubmit={
          !isDataChanged || !isRequiredFieldsFilled || !!fieldsError
        }
        isLoading={isUpdating}
        openDialog={addingCallOutStep === callOutSteps.addCallOut}
        submitButtonName="Optimize scheduling"
        submitCallback={optimizeScheduling}
        title="Callout"
      >
        <SettingsView
          activeCaregivers={filteredCaregivers}
          caregiversLoading={false}
          callOutData={callOutData}
          error={fieldsError}
          setCallOutData={setCallOutData}
          setError={setFieldsError}
          chosenCaregiver={chosenCaregiver}
        />
      </CustomDialog>
      <FullWidthDialog
        backButtonCallback={cancelCalloutCreation}
        cancelButtonName="cancel"
        cancelCallback={cancelCalloutCreation}
        disableAllActions={isUpdating}
        submitButtonName="Confirm callout"
        submitCallback={confirmCallout}
        title="Check Callout Possibility"
        openDialog={addingCallOutStep === callOutSteps.checkPossibility}
      >
        <CheckPossibilityView
          closeDialog={closeDialog}
          isLoading={isUpdating}
          callOutData={callOutData}
          possibilityData={possibilityData}
        />
      </FullWidthDialog>
    </>
  );
}
