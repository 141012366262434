import { Breadcrumbs, Typography } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../../../../api/Clients/api';
import { func, string } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { COLORS } from '../../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../../components/Alert';
import BreadcrumbsButton from '../../../../../../../components/BreadcrumbsButton';

export default function CustomBreadcrumbs({
  closeDialog = () => {},
  title = '',
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data: userData, error } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const userName = useMemo(
    () =>
      `${userData?.firstName} ${userData?.lastName}`,
    [userData],
  );
  const onLinkClick = ({ e, url }) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    } else {
      navigate(url);
      closeDialog();
    }
  };
  return (
    <Breadcrumbs aria-label="breadcrumbs">
      <BreadcrumbsButton
        name="Clients"
        onLinkClick={(e) => onLinkClick({ e, url: '/clients' })}
      />
      <BreadcrumbsButton
        name={userName}
        onLinkClick={(e) => onLinkClick({ e, url: `/clients/${id}` })}
      />
      <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
        {title}
      </Typography>
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  closeDialog: func,
  title: string,
};
