import { Box, Typography } from '@mui/material';
import { arrivalWindowDurationOptions } from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { visitTypeIcons } from '../../../../../../../../../../shared/constants';
import CustomSelect from '../../../../../../../../../../components/Select';

export default function ArrivalTimeSelect({
  changeArrivalWindowDuration = () => {},
  changeTypeOfDay = () => {},
  filtersData = {},
  visitData = {},
}) {
  return (
    <Box sx={{ display: 'flex', gap: '20px' }}>
      <CustomSelect
        endIcon={
          <img
            style={{ marginRight: '16px' }}
            src={visitTypeIcons[visitData?.visitType?.name?.toLowerCase()]}
            alt={visitData?.visitType?.name}
          />
        }
        hasSelectAllOption
        id="timeOfDays"
        isMultiselect
        isRequired
        options={filtersData?.timeOfDays}
        placeHolder="Time of Day"
        selectedValue={visitData?.timesOfDaysDetails}
        setValue={changeTypeOfDay}
      />
      <CustomSelect
        endIcon={
          <Typography sx={{ mr: '32px' }} variant="body1">
            min
          </Typography>
        }
        id="arrivalWindowDuration"
        isRequired
        options={arrivalWindowDurationOptions}
        placeHolder="Arrival Window Duration"
        selectedValue={{id: visitData?.arrivalWindowDuration, name: visitData?.arrivalWindowDuration}}
        setValue={changeArrivalWindowDuration}
        withIcon
      />
    </Box>
  );
}

ArrivalTimeSelect.propTypes = {
  changeArrivalWindowDuration: func,
  changeTypeOfDay: func,
  filtersData: instanceOf(Object),
  visitData: instanceOf(Object),
};
