import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const USER_DATA_API_KEY = 'userDataApi';

const userDataApi = createApi({
  reducerPath: USER_DATA_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getUserProfileData: builder.query({
      query: () => ({
        url: 'crud/users',
        method: 'GET',
        params: {
          id: '9c1bb9d4-2750-406b-80dc-f8d4f8d8f892',
        },
      }),
    }),
  }),
});
export const { useGetUserProfileDataQuery } = userDataApi;

export default userDataApi;
